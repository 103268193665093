import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PostApiCall from '../../helper/PostAPI';

function RestaurantTopBar() {
    const [offerList, setOfferList] = useState([]);
    useEffect(() => {
        getOffer()
    }, [])
    const getOffer = async () => {
        PostApiCall.postRequest(
            {
                status: "Active",
            },
            "getOfferList"
        ).then((result) =>
            result?.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    setOfferList(obj.message);
                }
            })
        );
    };
    // const [offers] = useState([
    //     { id: 1, message: "Get Free Samosa & Mix Veg Pakora on all orders above $45 - Use Code SAMOSA" },
    //     { id: 2, message: "Order Butter Chicken and get Free Naan - Use Code FREENAAN" }])
    const offerDiscounts = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    return (
        <Col lg={12} className="px-0 restaurant-topbar-offers">
            <div className="restaurant-topbar text-center py-2">
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={offerDiscounts}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    arrows={false}
                >
                    {offerList?.map((data) => {
                        return (
                            <>
                                <h6 className="mb-0 text-white text-center px-2">{data.fld_caption} - Use Code {data.fld_code}</h6>
                            </>
                        )
                    })}
                </Carousel>
            </div>
        </Col>
    );
}

export default RestaurantTopBar