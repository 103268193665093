import "animate.css";
import "./Banner.css";
import Bannerleft from "../../../pages/Masala-of-india/assets/images/banner/banneleftshape.png";
import Bannerright from "../../../pages/Masala-of-india/assets/images/banner/bannerightshape.png";
import Bannerbg from "../../../pages/Masala-of-india/assets/images/banner/bannerbg.png";

const CommonHomeBanner = () => {
  return (
    <>
      <section className="banner-section">
        <img
          className="img1 wow fadeInLeft"
          data-wow-delay=".7s"
          src={Bannerleft}
          alt="masala-of-india"
        />
        <img
          className="img2 wow fadeInRight"
          data-wow-delay=".7s"
          src={Bannerright}
          alt="masala-of-india"
        />
        <img className="img3" src={Bannerbg} alt="masla-of-india" />
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="main-content">
                <h2 className="main-sub-title wow fadeInLeft" data-wow-delay=".2s">
                  Taste Tradition, <br className="d-block d-md-none" /> Savor the Splendor!
                </h2>
                <h1 className="main-title wow fadeInRight mb-5 pb-lg-5" data-wow-delay=".2s">
                  Serving Exotic <br /> Indian Delicacies
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonHomeBanner;
