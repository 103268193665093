import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import DeliveryIcon from "../../../assets/img/order-type/HOMEDELIVERY.png";
import TakeawayIcon from "../../../assets/img/order-type/TAKEAWAY.png";
import CloseBanner from "../../../assets/img/monday-close-banner.jpg";
import "./OrderTypeModal.css";
export default function OrderTypeModal(props) {
  const [show, setShow] = useState(props.show);

  const handleClose = () => setShow(false);

  function UpdateCartAditionalInfo(deliveryType) {
    sessionStorage.setItem("OrderType", deliveryType);
    handleClose();
    // Notiflix.Loading.Dots();
    // PostApiCall.postRequest(
    //   {
    //     cartid: "",
    //     updatefor: 'orderingmethod',
    //     updatevalue: deliveryType
    //   },
    //   "updateCart"
    // ).then((result) =>
    //   result?.json().then((obj3) => {
    //     if (result.status == 200 || result.status == 201) {
    //       Notiflix.Loading.Remove();
    //     } else {
    //       Notiflix.Notify.Failure("Please try again later");
    //     }
    //   })
    // );
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        // size="xl"
        keyboard={false}
        centered
        className="order-type-modal">
        <Modal.Header className="">
          <h4 className="mb-0">Choose Your Ordering Method</h4>
        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <div>
            <img src={CloseBanner} className="img-fluid w-100" alt="Banner" />
          </div> */}
          <div className="d-flex justify-content-center">
            <div className="nav-item d-flex flex-column align-items-center">
              <img src={TakeawayIcon} className="img-fluid" />
              <button
                type="button"
                className="nav-link active"
                onClick={() => {
                  UpdateCartAditionalInfo("TakeAway");
                }}>
                Takeout
              </button>
            </div>
            <div className="nav-item d-flex flex-column align-items-center">
              <img src={DeliveryIcon} className="img-fluid" />
              <button
                type="button"
                className="nav-link active"
                onClick={() => {
                  UpdateCartAditionalInfo("Delivery");
                }}>
                Delivery
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
